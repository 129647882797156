<template>
  <div v-if="homeData" class="grid grid-cols-12 gap-6">
    <WidgetHeader :home-data="homeData"></WidgetHeader>
    <WidgetContent></WidgetContent>
  </div>
</template>

<script>
import WidgetHeader from "./components/header.vue";
import WidgetContent from "./components/content.vue";

import request from "@/utils/request";

export default {
  components: {
    WidgetHeader,
    WidgetContent,
  },
  data() {
    return {
      homeData: null,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      request({
        url: "/home/index/index",
      }).then((res) => {
        this.homeData = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
