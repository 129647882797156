<template>
  <div class="lg:grid-cols-8 col-span-12 grid xxl:grid-cols-9 mt-6 gap-6">
    <!-- 欢迎使用 -->
    <div class="lg:col-span-4 xxl:col-span-3 intro-y">
      <h2 class="text-lg font-medium truncate mb-4 font-bold">欢迎使用</h2>
      <div
        class="report-box zoom-in box p-4 border h-48"
        style="border-color: #fff; box-sizing: border-box"
      >
        <div class="flex pb-5 border-b">
          <div class="w-24 h-24 flex-none image-fit rounded-full overflow-hidden">
            <img alt="" :src="require(`@/assets/images/${$f()[9].photos[0]}`)" />
          </div>
          <div class="ml-3 font-medium">
            <div class="mb-3 mt-2 items-center">
              <span class="truncate">{{ homeData.user.nickname }}</span>
            </div>
            <div class="mb-2 items-center">
              <span class="truncate pr-2">用户状态:</span>
              <span class="text-theme-9 extraClass">启用</span>
            </div>
            <div class="truncate">
              <span class="pr-2">手机号:</span>
              <span>{{ homeData.user.phone }}</span>
            </div>
          </div>
        </div>
        <div class="flex items-center pt-4">
          <span class="truncate mr-auto">用户信息</span>
          <button class="button button--sm w-16 bg-theme-1 text-white">查看</button>
        </div>
      </div>
    </div>
    <!-- 交易总金额 -->
    <div class="lg:col-span-4 xxl:col-span-2 intro-y">
      <h2 class="text-lg font-medium truncate mb-4 font-bold">交易总金额</h2>
      <div
        class="report-box zoom-in box pt-4 pl-4 pb-4 border h-48 xxl:pl-2"
        style="border-color: #fff; box-sizing: border-box"
      >
        <div class="flex items-center pr-4">
          <div class="mr-auto w-5 h-5 flex items-center">
            <img src="@/assets/img/gold.png" class="w-5 h-5" alt="" />
          </div>
          <AlertCircleIcon class="w-5 h-5 text-gray-600"></AlertCircleIcon>
        </div>
        <div class="flex mt-2 h-32">
          <div class="flex w-46" style="flex-flow: column; flex-shrink: 0">
            <div class="text-2xl font-bold">¥{{ homeData.totalAmount.value }}</div>
            <div class="text-base text-gray-600 mt-1">交易总金额</div>
            <div class="text-xs text-gray-600 mt-auto extraClass2">
              更新时间：2021-09-10
            </div>
          </div>
          <div class="ml-auto mt-auto">
            <DonutChart
              :width="totalAmount.width"
              :height="totalAmount.height"
              :chart-data="totalAmount.moneyData"
              :options="totalAmount.options"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- 交易总笔数 -->
    <div class="lg:col-span-4 xxl:col-span-2 intro-y">
      <h2 class="text-lg font-medium truncate mb-4 font-bold">交易总笔数</h2>
      <div
        class="report-box zoom-in box pt-4 pl-4 pb-4 border h-48 xxl:pl-2"
        style="border-color: #fff; box-sizing: border-box"
      >
        <div class="flex items-center pr-4">
          <div class="mr-auto w-5 h-5 flex items-center">
            <img src="@/assets/img/deal.png" class="w-5 h-5" alt="" />
          </div>
          <AlertCircleIcon class="w-5 h-5 text-gray-600"></AlertCircleIcon>
        </div>
        <div class="flex mt-2 h-32">
          <div class="flex w-46" style="flex-flow: column; flex-shrink: 0">
            <div class="text-2xl font-bold">{{ homeData.totalOrders.value }}</div>
            <div class="text-base text-gray-600 mt-1">交易总笔数</div>
            <div class="text-xs text-gray-600 mt-auto extraClass2">
              更新时间：2021-09-10
            </div>
          </div>
          <div class="ml-auto mt-auto">
            <DonutChart
              :width="totalOrders.width"
              :height="totalOrders.height"
              :chart-data="totalOrders.moneyData"
              :options="totalOrders.options"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- 商户数量 -->
    <div class="lg:col-span-4 xxl:col-span-2 intro-y">
      <h2 class="text-lg font-medium truncate mb-4 font-bold">商户数量</h2>
      <div
        class="report-box zoom-in box pt-4 pl-4 pb-4 border h-48 xxl:pl-2"
        style="border-color: #fff; box-sizing: border-box"
      >
        <div class="flex items-center pr-4">
          <div class="mr-auto w-5 h-5 flex items-center">
            <img src="@/assets/img/merchants.png" class="w-5 h-5" alt="" />
          </div>
          <AlertCircleIcon class="w-5 h-5 text-gray-600"></AlertCircleIcon>
        </div>
        <div class="flex mt-2 h-32">
          <div class="flex w-46" style="flex-flow: column; flex-shrink: 0">
            <div class="text-2xl font-bold">{{ homeData.totalMerchants.value }}</div>
            <div class="text-base text-gray-600 mt-1">商户数量</div>
            <div class="text-xs text-gray-600 mt-auto extraClass2">
              更新时间：2021-09-10
            </div>
          </div>
          <div class="ml-auto mt-auto">
            <DonutChart
              :width="totalMerchants.width"
              :height="totalMerchants.height"
              :chart-data="totalMerchants.moneyData"
              :options="totalMerchants.options"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    homeData: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      moneyChart: {
        width: 130,
        height: 135,
        moneyData: {
          labels: ["总额", "新增"],
          datasets: [
            {
              data: [15, 10],
              backgroundColor: ["#1890FF", "#F98B2D"],
              hoverBackgroundColor: ["#1890FF", "#F98B2D"],
              borderWidth: 5,
              borderColor: "#fff",
            },
          ],
        },
        options: {
          cutoutPercentage: 75,
          responsive: true,
          legend: {
            maxHeight: 50,
            fullSize: false,
            position: "bottom",
            labels: {
              boxWidth: 12,
              padding: 5,
              fontSize: 12,
            },
          },
        },
      },
    };
  },
  computed: {
    totalAmount() {
      let totalAmount = this.homeData.totalAmount;
      return {
        width: 130,
        height: 135,
        moneyData: {
          labels: ["总额", "新增"],
          datasets: [
            {
              data: [totalAmount.value, totalAmount.increase],
              backgroundColor: ["#1890FF", "#F98B2D"],
              hoverBackgroundColor: ["#1890FF", "#F98B2D"],
              borderWidth: 5,
              borderColor: "#fff",
            },
          ],
        },
        options: {
          cutoutPercentage: 75,
          responsive: true,
          legend: {
            maxHeight: 50,
            fullSize: false,
            position: "bottom",
            labels: {
              boxWidth: 12,
              padding: 5,
              fontSize: 12,
            },
          },
        },
      };
    },
    totalMerchants() {
      let totalMerchants = this.homeData.totalMerchants;
      return {
        width: 130,
        height: 135,
        moneyData: {
          labels: ["总额", "新增"],
          datasets: [
            {
              data: [totalMerchants.value, totalMerchants.increase],
              backgroundColor: ["#1890FF", "#F98B2D"],
              hoverBackgroundColor: ["#1890FF", "#F98B2D"],
              borderWidth: 5,
              borderColor: "#fff",
            },
          ],
        },
        options: {
          cutoutPercentage: 75,
          responsive: true,
          legend: {
            maxHeight: 50,
            fullSize: false,
            position: "bottom",
            labels: {
              boxWidth: 12,
              padding: 5,
              fontSize: 12,
            },
          },
        },
      };
    },
    totalOrders() {
      let totalOrders = this.homeData.totalOrders;
      return {
        width: 130,
        height: 135,
        moneyData: {
          labels: ["总额", "新增"],
          datasets: [
            {
              data: [totalOrders.value, totalOrders.increase],
              backgroundColor: ["#1890FF", "#F98B2D"],
              hoverBackgroundColor: ["#1890FF", "#F98B2D"],
              borderWidth: 5,
              borderColor: "#fff",
            },
          ],
        },
        options: {
          cutoutPercentage: 75,
          responsive: true,
          legend: {
            maxHeight: 50,
            fullSize: false,
            position: "bottom",
            labels: {
              boxWidth: 12,
              padding: 5,
              fontSize: 12,
            },
          },
        },
      };
    },
  },
  mounted() {
    console.log(this.homeData);
  },
};
</script>

<style lang="scss" scoped>
.extraClass::before {
  content: "";
  margin-right: 5px;
  width: 5px;
  height: 5px;
  background: rgba(145, 199, 20, var(--text-opacity));
  border-radius: 50%;
  display: inline-block;
  vertical-align: 2px;
}

.extraClass2::before {
  content: "";
  margin-right: 8px;
  width: 5px;
  height: 5px;
  background: #ff4141;
  border-radius: 50%;
  display: inline-block;
  vertical-align: 2px;
}
</style>
