var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"xxl:grid-cols-11 grid col-span-12 gap-6 grid-cols-12 "},[_c('div',{staticClass:"xxl:col-span-4 col-span-12"},[_c('div',{staticClass:"mb-5 "},[_c('h2',{staticClass:"text-lg font-medium truncate mb-4 font-bold intro-y"},[_vm._v("今日交易金额")]),_c('div',{staticClass:"report-box zoom-in box p-4 intro-y"},[_c('div',{staticClass:"flex items-center"},[_c('span',{staticClass:"mr-auto"},[_vm._v("交易金额")]),_c('AlertCircleIcon',{staticClass:"w-5 h-5 text-gray-600"})],1),_c('div',{staticClass:"flex items-baseline pt-3"},[_c('span',{staticClass:"text-theme-1 font-bold text-xl"},[_vm._v("¥1100.10")]),_c('span',{staticClass:"ml-1",staticStyle:{"font-size":"12px"}},[_c('font',[_vm._v("共")]),_c('font',{staticStyle:{"color":"#ff4242"}},[_vm._v("200")]),_c('font',[_vm._v("笔")])],1)]),_c('LineChart',{attrs:{"height":37,"chart-data":_vm.lineChartData.data,"options":_vm.lineChartData.options}}),_vm._m(0)],1)]),_c('div',{},[_c('h2',{staticClass:"text-lg font-medium truncate mb-4 font-bold intro-y"},[_vm._v("支付方式比例")]),_c('div',{staticClass:"report-box zoom-in box p-4 intro-y"},[_c('div',{staticClass:"flex items-center mb-3"},[_c('span',{staticClass:"mr-auto text-base text-gray-600"},[_vm._v("支付方式比例概览")]),_c('div',{staticClass:"relative ml-auto"},[_c('div',{staticClass:"absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 border text-gray-600"},[_c('CalendarIcon',{staticClass:"w-4 h-4"})],1),_c('LitePicker',{staticClass:"input w-56 border block pl-12 text-xs",attrs:{"options":{
                buttonText: { apply: '确定', cancel: '取消' },
                lang: 'zh-cn',
                autoApply: false,
                singleMode: false,
                numberOfColumns: 2,
                numberOfMonths: 2,
                showWeekNumbers: false,
                dropdowns: {
                  minYear: 1990,
                  maxYear: null,
                  months: true,
                  years: true,
                },
              }},model:{value:(_vm.daterange),callback:function ($$v) {_vm.daterange=$$v},expression:"daterange"}})],1)]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"w-48 h-48 flex-0 mr-auto"},[_c('PieChart',{attrs:{"width":_vm.pieCharData.width,"height":_vm.pieCharData.height,"chart-data":_vm.pieCharData.data,"options":_vm.pieCharData.options}})],1),_vm._m(1)])])])]),_c('div',{staticClass:"xxl:col-span-7 col-span-12 intro-y"},[_c('h2',{staticClass:"text-lg font-medium truncate mb-4 font-bold"},[_vm._v("交易统计")]),_c('div',{staticClass:"report-box zoom-in box p-4 intro-y"},[_c('div',{staticClass:"flex items-center mb-3"},[_c('span',{staticClass:"mr-auto text-base text-gray-600"},[_vm._v("统计概览")]),_c('div',{staticClass:"relative ml-auto"},[_c('div',{staticClass:"absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 border text-gray-600"},[_c('CalendarIcon',{staticClass:"w-4 h-4"})],1),_c('LitePicker',{staticClass:"input w-56 border block pl-12 text-xs",attrs:{"options":{
              buttonText: { apply: '确定', cancel: '取消' },
              lang: 'zh-cn',
              autoApply: false,
              singleMode: false,
              numberOfColumns: 2,
              numberOfMonths: 2,
              showWeekNumbers: false,
              dropdowns: {
                minYear: 1990,
                maxYear: null,
                months: true,
                years: true,
              },
            }},model:{value:(_vm.daterange),callback:function ($$v) {_vm.daterange=$$v},expression:"daterange"}})],1)]),_c('div',[_c('BarChart',{attrs:{"width":_vm.barChartData.width,"height":_vm.barChartData.height,"chart-data":_vm.barChartData.data,"options":_vm.barChartData.options}})],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-center text-gray-600 text-xs whitespace-no-wrap"},[_c('div',[_vm._v("昨日交易金额：￥800.00")]),_c('div',{staticClass:"ml-auto"},[_vm._v("近七天交易金额：￥2000.00")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-3 pb-3 text-xs text-gray-600"},[_c('div',{staticClass:"mb-4 mt-5 flex"},[_c('span',{staticClass:"bls flex mr-16 w-20",staticStyle:{"--bls-background":"#999999"}},[_vm._v("总金额")]),_c('span',{staticClass:"ml-auto"},[_vm._v("¥110004.51")])]),_c('div',{staticClass:"mb-4 flex"},[_c('span',{staticClass:"bls flex mr-16 w-20",staticStyle:{"--bls-background":"#2cb431"}},[_vm._v("微信")]),_c('span',{staticClass:"ml-auto"},[_vm._v("62%")])]),_c('div',{staticClass:"mb-4 flex"},[_c('span',{staticClass:"bls flex mr-16 w-20",staticStyle:{"--bls-background":"#1890ff"}},[_vm._v("支付宝")]),_c('span',{staticClass:"ml-auto"},[_vm._v("33%")])]),_c('div',{staticClass:"flex"},[_c('span',{staticClass:"bls flex mr-16 w-20",staticStyle:{"--bls-background":"#f98b2d"}},[_vm._v("云闪付")]),_c('span',{staticClass:"ml-auto"},[_vm._v("10%")])])])}]

export { render, staticRenderFns }