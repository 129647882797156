<template>
  <div class="xxl:grid-cols-11 grid col-span-12 gap-6 grid-cols-12 ">
    <div class="xxl:col-span-4 col-span-12">
      <div class="mb-5 ">
        <h2 class="text-lg font-medium truncate mb-4 font-bold intro-y">今日交易金额</h2>
        <div class="report-box zoom-in box p-4 intro-y">
          <div class="flex items-center">
            <span class="mr-auto">交易金额</span>
            <AlertCircleIcon class="w-5 h-5 text-gray-600"></AlertCircleIcon>
          </div>

          <div class="flex items-baseline pt-3">
            <span class="text-theme-1 font-bold text-xl">¥1100.10</span>
            <span style="font-size: 12px" class="ml-1">
              <font>共</font>
              <font style="color: #ff4242">200</font>
              <font>笔</font>
            </span>
          </div>
          <LineChart
            :height="37"
            :chart-data="lineChartData.data"
            :options="lineChartData.options"
          />
          <div class="flex items-center text-gray-600 text-xs whitespace-no-wrap">
            <div>昨日交易金额：￥800.00</div>
            <div class="ml-auto">近七天交易金额：￥2000.00</div>
          </div>
        </div>
      </div>
      <div class="">
        <h2 class="text-lg font-medium truncate mb-4 font-bold intro-y">支付方式比例</h2>
        <div class="report-box zoom-in box p-4 intro-y">
          <div class="flex items-center mb-3">
            <span class="mr-auto text-base text-gray-600">支付方式比例概览</span>
            <div class="relative ml-auto">
              <div
                class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 border text-gray-600"
              >
                <CalendarIcon class="w-4 h-4" />
              </div>
              <LitePicker
                v-model="daterange"
                :options="{
                  buttonText: { apply: '确定', cancel: '取消' },
                  lang: 'zh-cn',
                  autoApply: false,
                  singleMode: false,
                  numberOfColumns: 2,
                  numberOfMonths: 2,
                  showWeekNumbers: false,
                  dropdowns: {
                    minYear: 1990,
                    maxYear: null,
                    months: true,
                    years: true,
                  },
                }"
                class="input w-56 border block pl-12 text-xs"
              />
            </div>
          </div>
          <div class="flex">
            <div class="w-48 h-48 flex-0 mr-auto">
              <PieChart
                :width="pieCharData.width"
                :height="pieCharData.height"
                :chart-data="pieCharData.data"
                :options="pieCharData.options"
              />
            </div>
            <div class="pt-3 pb-3 text-xs text-gray-600">
              <div class="mb-4 mt-5 flex">
                <span class="bls flex mr-16 w-20" style="--bls-background: #999999"
                  >总金额</span
                >
                <span class="ml-auto">¥110004.51</span>
              </div>
              <div class="mb-4 flex">
                <span class="bls flex mr-16 w-20" style="--bls-background: #2cb431"
                  >微信</span
                >
                <span class="ml-auto">62%</span>
              </div>
              <div class="mb-4 flex">
                <span class="bls flex mr-16 w-20" style="--bls-background: #1890ff"
                  >支付宝</span
                >
                <span class="ml-auto">33%</span>
              </div>
              <div class="flex">
                <span class="bls flex mr-16 w-20" style="--bls-background: #f98b2d"
                  >云闪付</span
                >
                <span class="ml-auto">10%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="xxl:col-span-7 col-span-12 intro-y">
      <h2 class="text-lg font-medium truncate mb-4 font-bold">交易统计</h2>
      <div class="report-box zoom-in box p-4 intro-y">
        <div class="flex items-center mb-3">
          <span class="mr-auto text-base text-gray-600">统计概览</span>
          <div class="relative ml-auto">
            <div
              class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 border text-gray-600"
            >
              <CalendarIcon class="w-4 h-4" />
            </div>
            <LitePicker
              v-model="daterange"
              :options="{
                buttonText: { apply: '确定', cancel: '取消' },
                lang: 'zh-cn',
                autoApply: false,
                singleMode: false,
                numberOfColumns: 2,
                numberOfMonths: 2,
                showWeekNumbers: false,
                dropdowns: {
                  minYear: 1990,
                  maxYear: null,
                  months: true,
                  years: true,
                },
              }"
              class="input w-56 border block pl-12 text-xs"
            />
          </div>
        </div>
        <div>
          <BarChart
            :width="barChartData.width"
            :height="barChartData.height"
            :chart-data="barChartData.data"
            :options="barChartData.options"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      daterange: "",
      lineChartData: {
        data: {
          labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          datasets: [
            {
              label: "Html Template",
              data: [0, 200, 250, 200, 500, 450, 850, 1050, 950, 1100, 900, 1200],
              borderWidth: 2,
              borderColor: "#1890FF30",
              backgroundColor: "transparent",
              pointBorderColor: "transparent",
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  display: false,
                },
                gridLines: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  display: false,
                },
                gridLines: {
                  display: false,
                },
              },
            ],
          },
        },
      },
      pieCharData: {
        width: 100,
        height: 100,
        data: {
          labels: ["微信", "支付宝", "云闪付"],
          datasets: [
            {
              data: [15, 10, 65],
              backgroundColor: ["#2CB431", "#1890FF", "#F98B2D", "#999999"],
              hoverBackgroundColor: ["#2CB431", "#1890FF", "#F98B2D", "#999999"],
              borderWidth: 7,
              borderColor: cash("html").hasClass("dark") ? "#303953" : "#fff",
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
        },
      },
      barChartData: {
        width: 0,
        height: 412,
        data: {
          labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"],
          datasets: [
            {
              label: "收款",
              barPercentage: 0.5,
              barThickness: 16,
              maxBarThickness: 16,
              minBarLength: 2,
              data: [0, 200, 250, 200, 500, 450, 850, 1050],
              backgroundColor: "#F98B2D",
            },
            {
              label: "退款",
              barPercentage: 0.5,
              barThickness: 16,
              maxBarThickness: 16,
              minBarLength: 2,
              data: [0, 300, 400, 560, 320, 600, 720, 850],
              backgroundColor: "#FF4242",
            },
          ],
        },
        options: {
          legend: {
            align: "start",
            labels: {
              boxWidth: 12,
              padding: 15,
              fontSize: 12,
            },
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  fontSize: "12",
                  fontColor: "#4A5568",
                },
                gridLines: {
                  display: false,
                },
              },
            ],
           
            yAxes: [
              {
   
                ticks:{
                  fontSize: '12',
                  fontColor: '#4A5568',
                },
                gridLines: {
                  color: "#D8D8D8",
                  zeroLineColor: "#D8D8D8",
                  borderDash: [10, 10],
                  zeroLineBorderDash: [1, 1],
                  drawBorder: false,
                },
              },
            ],
          },
        },
      },
    };
  },
  watch: {
    daterange(val) {
      console.log(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.bls {
  align-items: center;
}
.bls::before {
  content: "";
  width: 5px;
  height: 5px;
  margin-right: 6px;
  border-radius: 50%;
  background: var(--bls-background);
}
</style>
